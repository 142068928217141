<template>
  <div class="companies">
    <MobileTable
      v-model:selection="store.state.voting.selectedVotings"
      label="Votaciones"
      :columns="voting.columns"
      :data="store.state.voting.votings"
    />
    <DesktopTable
      v-model:selection="store.state.voting.selectedVotings"
      label="Votaciones"
      :columns="voting.columns"
      :data="store.state.voting.votings"
    />
    <Actions :items="voting.actions" />
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions } from "../../constants";

export default {
  components: { MobileTable, DesktopTable, Actions },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/votaciones/nuevo");
    };
    /* const onRemove = async () => {
      if (store.state.voting.selectedVotings.length !== 1) {
          store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.votingActions.delete,
          store.state.voting.selectedVotings[0].id
        );
        if (response.ok) {
          store.commit("setSelectedVotings", []);
          await store.dispatch(actions.votingActions.findAll, {
            buildingId: store.state.general.selectedBuilding,
          });
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
            store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    }; */
    const onChangeStatus = async (statusId) => {
      if (!store.state.voting.selectedVotings.length) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (!store.state.voting.selectedVotings[0].quantity) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe agregar votaciones!",
          life: 5000,
        });
      } else if (
        store.state.voting.selectedVotings[0].statusId === 2 &&
        statusId === 2
      ) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Esta votación ya ha iniciado!",
          life: 5000,
        });
      } else if (store.state.voting.selectedVotings[0].statusId === 4) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Esta votación ya ha finalizado!",
          life: 5000,
        });
      } else {
        console.log(store.state.voting.selectedVotings[0]);
        store.commit("setLoading", true);
        const response = await store.dispatch(
          actions.votingActions.changeStatus,
          {
            statusId,
            votings: store.state.voting.selectedVotings.map((item) => item.id),
          }
        );
        if (response.ok) {
          await store.dispatch(actions.votingActions.findAll, {
            buildingId: store.state.general.selectedBuilding,
          });

          store.commit("setSelectedVotings", []);

          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    };
    const onDetail = async () => {
      if (store.state.voting.selectedVotings.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setOpenMode", openMode.DETAIL);
        router.push("/votaciones/detalle/asociar");
      }
    };

    const voting = reactive({
      columns: [
        {
          field: "title",
          header: "Título",
          sort: true,
          center: true,
        },
        {
          field: "fromDate",
          header: "Fecha de Inicio",
          sort: true,
          date: true,
        },
        {
          field: "toDate",
          header: "Fecha de Fin",
          sort: true,
          date: true,
        },
        {
          field: "quantity",
          header: "Votaciones",
          center: true,
        },
        {
          field: "createdAt",
          header: "Fecha de Creación",
          sort: true,
          date: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Nuevo",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/nuevo"),
        },
        {
          name: "Detalle de votación",
          action: onDetail,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/nuevo"),
        },
        {
          name: "Iniciar",
          action: () => onChangeStatus(2),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/nuevo"),
        },
        {
          name: "Finalizar",
          action: () => onChangeStatus(4),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/votaciones/nuevo"),
        },
        /* {
          name: "Eliminar",
          action: onRemove,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/asambleas/nuevo"),
        }, */
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedVotings", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.votingActions.findAll, {
        buildingId: store.state.general.selectedBuilding,
      });
      store.commit("setLoading", false);
    });

    return { store, voting };
  },
};
</script>

<style scoped></style>
